import { ref } from 'vue'
import { ConditionalStyle, ConditionalStyleProperties, Levels } from '../features/layer/@types/layer'
import LayerMapService, { LEVEL_KEY } from '../features/map/layerMap.service'
import { divisionIntention } from '../@types/Opportunity'
import Layer from '../features/layer/layer.entities'

export function useLayer() {
  const currentMapLayer = ref<google.maps.Data>() 

  function isLayerAlreadyOnMap(layer: Layer) {
    const layerLevelKey = currentMapLayer.value?.get(LEVEL_KEY)
    return layer[LEVEL_KEY] === layerLevelKey
  }

  function getLayerStyle(rules: ConditionalStyle[], styleProperties: ConditionalStyleProperties) {
    return LayerMapService.getLayerStyleBasedOnRules(rules, styleProperties)
  }

  function getLayerData(geojsonId: string, intentions: divisionIntention[]) {
    return intentions.find(intention => intention.divisionId === geojsonId)
  }

  return {
    currentMapLayer,
    isLayerAlreadyOnMap,
    getLayerStyle,
    getLayerData,
  }
}

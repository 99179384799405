import { Levels } from './@types/layer'
import Layer from './layer.entities'
import LayerStorage from './layer.storage'

class LayerRepository {
  private storage: LayerStorage

  constructor() {
    this.storage = new LayerStorage()
  }

  add(layer: Layer) {
    this.storage.add(layer)
    return layer
  }

  get(level: Levels) {
    return this.storage.get(level)
  }

  getAll() {
    return this.storage.getAll()
  }

  getLayerByZoom(zoom: number) {
    const lastLayer = Array.from(this.getAll().values()).pop()
    for (const [key, layer] of this.getAll()) {
      if (layer.breakpoint && zoom < layer.breakpoint) return layer
    }
  
    return lastLayer
  }

  clearStorage() {
    this.storage.clear()
  }
}

export default LayerRepository

import { ref } from 'vue'
import { Drawer } from '../@types/Overlay'
import { divisionIntention } from '../@types/Opportunity'
import { Properties } from '../features/layer/@types/layer'

const initDrawer = () => ({ show: false, color: '', intention: null })
const drawer = ref<Drawer>(initDrawer())

export function useDrawer() {
  function closeDrawer(mapLayer?: google.maps.Data) {
    if (mapLayer) mapLayer.revertStyle()

    drawer.value = initDrawer()
  }

  function setDrawerProperties(data: divisionIntention, style: Properties | undefined) {
    drawer.value.intention = data
    drawer.value.color = style?.fillColor || ''
  }

  return {
    drawer,
    closeDrawer,
    setDrawerProperties,
  }
}

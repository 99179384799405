import { render, staticRenderFns } from "./Data.vue?vue&type=template&id=855f1122&scoped=true"
import script from "./Data.vue?vue&type=script&setup=true&lang=ts"
export * from "./Data.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Data.vue?vue&type=style&index=0&id=855f1122&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "855f1122",
  null
  
)

export default component.exports
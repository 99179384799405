export type Monetization = {
  currency: Currency,
  averageValue: number
}

export type LatLng = {
  lat: number
  lng: number
}

// GEOJSON
export type divisionIntention = {
  divisionName: string
  divisionId: string
  totalCount: number
  totalValue: number
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CAD = 'CAD'
}

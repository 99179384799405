import { User } from '@/features/@types/AppContext'
import { PROXY_URI } from '../@types/Proxy'
import { HttpRepository } from '../api/repositories/http.repository'
import OpportunityService from '../api/services/opportunity.service'
import ClientService from '../features/client/client.service'
import LayerRepository from '../features/layer/layer.repository'
import LayerService from '../features/layer/layer.service'
import GmapProvider from '../features/map/gmap.provider'
import LayerGmapProvider from '../features/map/layerGmap.provider'
import LayerMapService from '../features/map/layerMap.service'
import MapService from '../features/map/map.service'
import SequentialTaskExecutor from '../utils/sequentialTaskExecutor.util'

type AppContext = {
  currentUser: User
  clientId: string
}

export function useOpportunityModules(appContext: AppContext) {
  const mapAdapter = new GmapProvider()
  const mapService = new MapService(mapAdapter)
  const layerMapService = new LayerMapService(new LayerGmapProvider(mapAdapter))
  
  const layerService = new LayerService(new LayerRepository())
  
  const httpRepository = new HttpRepository(`${PROXY_URI.OPPORTUNITY}clients/${appContext.clientId}`)
  const clientService = new ClientService(httpRepository)
  const opportunityService = new OpportunityService(httpRepository)
  const zoomSequentialTaskExecutor = new SequentialTaskExecutor()

  return {
    clientService,
    mapService,
    layerService,
    layerMapService,
    opportunityService,
    zoomSequentialTaskExecutor
  }
}
import { divisionIntention } from '../../@types/Opportunity'
import { ClientLayer, DivisionKey, Levels, Style } from './@types/layer'

class Layer {
  level: Levels
  type: DivisionKey
  geojsonUrl: string
  breakpoint?: number
  style: Style
  intentions: divisionIntention[]

  constructor(level: Levels, type: DivisionKey, geojsonUrl: string, style: Style, intentions?: divisionIntention[], breakpoint?: number) {
    this.level = level
    this.type = type
    this.geojsonUrl = geojsonUrl
    this.breakpoint = breakpoint
    this.style = style
    this.intentions = intentions || []
  }

  setIntentions(intentions: divisionIntention[]) {
    this.intentions = intentions
  }
}

export default Layer

function createLayer(level: Levels, type: DivisionKey, geojsonUrl: string, style: Style, intentions?: divisionIntention[], breakpoint?: number) {
  return new Layer(level, type, geojsonUrl, style, intentions, breakpoint)
}

export function createLayers(layers: ClientLayer[]) {
  const createdLayers = []
  for (const layer of layers) {
    createdLayers.push(createLayer(layer.level, layer.type, layer.geojsonUrl, layer.style, layer.intentions, layer.breakpoint))
  }

  return createdLayers
}

import { Levels } from '../features/layer/@types/layer'
import { GEOJSON_ID } from '../features/map/layerGmap.provider'
import { LEVEL_KEY } from '../features/map/layerMap.service'
import { useDrawer } from './useDrawer.composable'
import { usePopover } from './usePopover'
import { getPopoverPositionInWindow } from '../utils/popover.util'
import { useLayer } from './useLayer.composable'
import LayerService from '../features/layer/layer.service'

export function useLayerInteractions(
  layerService: LayerService,
  emit: (event: string, ...args: any[]) => void
) {
  const { drawer, setDrawerProperties } = useDrawer()
  const { popover, closePopover, setPopoverProperties } = usePopover()
  const { getLayerStyle, getLayerData } = useLayer()

  function onLayerMouseClick(event: google.maps.Data.MouseEvent, mapLayer: google.maps.Data) {
    const geojsonId = event.feature.getProperty(GEOJSON_ID) as string
    const level: Levels = mapLayer.get(LEVEL_KEY)
    const clientLayer = layerService.getLayer(level)
    if (!clientLayer) return
  
    const layerData = getLayerData(geojsonId, clientLayer.intentions)

    if (!layerData) return

    emit('toggleDrawer', false)

    const layerStyle = getLayerStyle(clientLayer.style.rules, { totalCount: layerData.totalCount })
    setDrawerProperties(layerData, layerStyle)
    mapLayer.revertStyle()
    mapLayer.overrideStyle(event.feature, { strokeWeight: 4, zIndex: 99 })

    drawer.value.show = true
  }
  
  function onLayerMouseMove(event: google.maps.Data.MouseEvent) {
    const { pageY, pageX } = event.domEvent as MouseEvent
    const { top, left } = getPopoverPositionInWindow('popover-geojson', pageY, pageX)
  
    popover.value.style.top = top
    popover.value.style.left = left
    popover.value.show = true
  }
  
  function onLayerMouseOut(event: google.maps.Data.MouseEvent, mapLayer: google.maps.Data) {
    const geojsonId = event.feature.getProperty(GEOJSON_ID) as string
    const openInsideDrawer = geojsonId === drawer.value.intention?.divisionId
  
    closePopover()
    if (!openInsideDrawer) mapLayer.revertStyle(event.feature)
  }
  
  function onLayerMouseOver(event: google.maps.Data.MouseEvent, mapLayer: google.maps.Data) {
    const geojsonId = event.feature.getProperty(GEOJSON_ID) as string
    const level: Levels = mapLayer.get(LEVEL_KEY)
    const clientLayer = layerService.getLayer(level)
    if (!clientLayer) return

    const layerData = getLayerData(geojsonId, clientLayer.intentions)
  
    if (layerData) {
      const layerStyle = getLayerStyle(clientLayer.style.rules, { totalCount: layerData.totalCount })
      setPopoverProperties(layerData, layerStyle)
    }
  
    mapLayer.overrideStyle(event.feature, { strokeWeight: 4, zIndex: 99 })
  }

  return {
    onLayerMouseClick,
    onLayerMouseMove,
    onLayerMouseOver,
    onLayerMouseOut
  }
}
import { GOOGLE_MAPS_API_KEY } from '@/features/opportunity/config/google-keys.config'
import { GmapEvent } from './@types/Event'

const ZOOM_INIT = 0

class GmapProvider {
  private map: google.maps.Map | null = null

  createScript(scriptId: string, elementId: string, options: google.maps.MapOptions): Promise<google.maps.Map | unknown> {
    return new Promise((resolve, reject) => {
      if (document.getElementById(scriptId)) {
        return resolve(this.initializeMap(elementId, options))
      }

      const googleMapsScript = document.createElement('script')
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`
      googleMapsScript.id = scriptId
      googleMapsScript.async = true
      googleMapsScript.defer = true
  
      googleMapsScript.addEventListener('load', async () => {
        try {
          const map = await this.initializeMap(elementId, options)
          resolve(map)
        } catch (error) {
          reject(error)
        }
      })
  
      googleMapsScript.addEventListener('error', (error) => {
        reject(error)
      })
  
      document.body.appendChild(googleMapsScript)
    })
  }

  private async initializeMap(id: string, options: google.maps.MapOptions) {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary

    this.map = new Map(
      document.getElementById(id) as HTMLElement,
      options
    )

    return Promise.resolve(this.map)
  }

  getMap(): google.maps.Map | null {
    return this.map
  }

  getMapZoom() {
    return this.map?.getZoom()
  }

  zoomIn() {
    let currentZoom = this.getMapZoom() || ZOOM_INIT
    this.map?.setZoom(++currentZoom)
  }

  zoomOut() {
    let currentZoom = this.getMapZoom() || ZOOM_INIT
    this.map?.setZoom(--currentZoom)
  }

  setZoomChangedListener(callback: Function) {
    this.map?.addListener(GmapEvent.ZOOM_CHANGED, callback)
  }

  clearListener() {
    if (this.map) google.maps.event.clearInstanceListeners(this.map)
  }

  clearMapAndListener() {
    this.clearListener()
    this.map = null
  }
}

export default GmapProvider

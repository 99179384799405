import { ref } from 'vue'
import { Popover } from '../@types/Overlay'
import { divisionIntention } from '../@types/Opportunity'
import { Properties } from '../features/layer/@types/layer'

const initPopover = () => (
  {
    show: false,
    style: {
      top: '',
      left: ''
    },
    color: '',
    intention: null
  }
)
const popover = ref<Popover>(initPopover())

export function usePopover() {
  function closePopover() {
    popover.value = initPopover()
  }

  function setPopoverProperties(intention: divisionIntention, style?: Properties) {
    popover.value.intention = intention
    popover.value.color = style?.fillColor || ''
  }

  return {
    popover,
    closePopover,
    setPopoverProperties,
  }
}

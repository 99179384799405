import MockAdapterClass from '@/features/mocks/axios.mock'
import { HttpRepository } from '../../api/repositories/http.repository'
import { ClientConfig } from './@types/config'
import { ClientConfigDto, CountryDto, LayerDto } from './@types/dto'
import { Monetization } from '../../@types/Opportunity'
import { createClient } from './client.entities'
import { ClientLayer } from '../layer/@types/layer'

class ClientService {
  private _repository: HttpRepository

  constructor(repository: HttpRepository) {
    this._repository = repository
  }

  #layersWithGeojsonUrl(clientLayers: LayerDto[], divisions: CountryDto['divisions']) {
    const layers: ClientLayer[] = []

    for (const layer of clientLayers) {
      const clientLayer = {
        ...layer,
        geojsonUrl: divisions[layer.type].geojsonUrl,
      }

      layers.push(clientLayer)
    }

    return layers
  }

  async getClient(): Promise<ClientConfig> {
    const client = await MockAdapterClass.GET<ClientConfigDto>('')
    const [countryGeojsons, monetization] = await Promise.all([
      this._repository.GET<CountryDto>(`/countries/${client.country}`),
      this._repository.GET<Monetization>(`/monetization`),
    ])

    const layers = this.#layersWithGeojsonUrl(client.layers, countryGeojsons.divisions)

    return createClient(
      { 
        zoom: client.zoom, 
        center: client.center,
        country: client.country,
        mapId: client.mapId,
        layers: layers,
        monetization
      }
    )
  }
}

export default ClientService
